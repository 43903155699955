import { Button } from "@/components/ui/button";
import useUser from "@/hooks/useUser";
import useWallet from "@/hooks/useWallet";
import EditProfile from "@/pages/Profile/EditProfile";
import displayAddress from "@/Utils/displayAddress";
import { usePrivy } from "@privy-io/react-auth";
import { ChevronDownIcon, CopyIcon } from "@radix-ui/react-icons";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useBalance, useDisconnect } from "wagmi";
import { FormatEthAmount } from "./FormatAmount";
import Image from "./Image";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { useToast } from "./ui/use-toast";

const CustomConnectButton = () => {
  const wallet = useWallet();
  const { data: user, isFetched } = useUser();
  const { data: balance, refetch: refetchBalance } = useBalance({
    address: wallet?.address,
  });
  const { toast } = useToast();
  const { login, logout, authenticated } = usePrivy();
  const { disconnect } = useDisconnect();

  useEffect(() => {
    if (isFetched) refetchBalance();
  }, [isFetched, refetchBalance]);

  if (authenticated && !user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {authenticated ? (
        <Dialog>
          <DialogTrigger asChild>
            <div className="flex min-w-0 max-w-fit cursor-pointer items-center gap-1 rounded-full bg-b1 p-1">
              <Image
                src={user?.image}
                address={wallet?.address || "0x"}
                isNsfw={false}
                variant="profile"
                className="h-6 w-6 rounded-full"
              />
              <p className="text-md">{user?.username}</p>
              <ChevronDownIcon className="h-4 w-4 flex-none" />
            </div>
          </DialogTrigger>
          <DialogContent>
            <DialogTitle className="hidden">{user?.username}</DialogTitle>
            <DialogDescription className="hidden"></DialogDescription>
            <div className="flex flex-col items-center gap-1">
              <Image
                src={user?.image}
                address={wallet?.address || "0x"}
                isNsfw={false}
                variant="profile"
                className="h-24 w-24 rounded-full"
              />
              <div className="flex items-center gap-1">
                <p className="text-xl font-semibold">{user?.username}</p>
                {user && <EditProfile user={user} />}
              </div>
              <div className="flex items-center gap-1">
                <p className="text-sm">{displayAddress(wallet?.address)}</p>
                <Button
                  variant="ghost"
                  size="icon"
                  className="bg-gray-800"
                  onClick={() => {
                    navigator.clipboard.writeText(wallet?.address || "");
                    toast({
                      title: "Address copied",
                    });
                  }}
                >
                  <CopyIcon className="h-3 w-3" />
                </Button>
              </div>
              <p className="text-sm">
                <FormatEthAmount eth={balance?.value} /> ETH
              </p>
              <DialogClose asChild>
                <Link
                  to={`/devs/${wallet?.address}`}
                  className="text-sm text-link"
                >
                  View Profile
                </Link>
              </DialogClose>
              <div className="mt-6 flex gap-2">
                <Button
                  variant="destructive"
                  onClick={() => {
                    logout();
                    disconnect();
                  }}
                >
                  Logout
                </Button>
                <DialogClose asChild>
                  <Button variant="outline">Close</Button>
                </DialogClose>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <Button variant="outline" onClick={login}>
          Login
        </Button>
      )}
    </>
  );

  // return (
  //   <ConnectButton.Custom>
  //     {({
  //       account,
  //       chain,
  //       openAccountModal,
  //       openChainModal,
  //       openConnectModal,
  //       authenticationStatus,
  //       mounted,
  //     }) => {
  //       // Note: If your app doesn't use authentication, you
  //       // can remove all 'authenticationStatus' checks
  //       const ready = mounted && authenticationStatus !== "loading";
  //       const connected =
  //         ready &&
  //         account &&
  //         chain &&
  //         (!authenticationStatus || authenticationStatus === "authenticated");
  //       return (
  //         <div
  //           {...(!ready && {
  //             "aria-hidden": true,
  //             style: {
  //               opacity: 0,
  //               pointerEvents: "none",
  //               userSelect: "none",
  //             },
  //           })}
  //           className="w-full"
  //         >
  //           {(() => {
  //             if (!connected) {
  //               return (
  //                 <Button
  //                   variant="outline"
  //                   className="w-full rounded-full"
  //                   // onClick={openConnectModal}
  //                   onClick={login}
  //                 >
  //                   Login
  //                 </Button>
  //               );
  //             }
  //             if (chain.unsupported) {
  //               return (
  //                 <div className="group mx-auto w-fit">
  //                   <Button
  //                     className="w-fit text-nowrap py-[2.5px] text-md text-red group-hover:hidden"
  //                     variant="outline"
  //                     size="md"
  //                   >
  //                     Wrong Network
  //                   </Button>
  //                   <Button
  //                     className="hidden rounded-full group-hover:block"
  //                     variant="ghost"
  //                     size="md"
  //                     onClick={openChainModal}
  //                   >
  //                     Change network
  //                   </Button>
  //                 </div>
  //               );
  //             }
  //             if (variant === "minimal")
  //               return (
  //                 <Dialog>
  //                   <DialogTrigger asChild>
  //                     <div className="flex min-w-0 max-w-fit cursor-pointer items-center gap-1 rounded-full bg-b1 p-1">
  //                       <Image
  //                         src={user?.profile_image}
  //                         address={wallet?.address || "0x"}
  //                         isNsfw={user?.is_nsfw ?? true}
  //                         variant="profile"
  //                         className="h-6 w-6 rounded-full"
  //                       />
  //                       <p className="text-md">{user?.username}</p>
  //                       <ChevronDownIcon className="h-4 w-4 flex-none" />
  //                     </div>
  //                   </DialogTrigger>
  //                   <DialogContent>
  //                     <DialogTitle className="hidden">
  //                       {user?.username}
  //                     </DialogTitle>
  //                     <DialogDescription className="hidden"></DialogDescription>
  //                     <div className="flex flex-col items-center gap-1">
  //                       <Image
  //                         src={user?.profile_image}
  //                         address={wallet?.address || "0x"}
  //                         isNsfw={user?.is_nsfw ?? true}
  //                         variant="profile"
  //                         className="h-24 w-24 rounded-full"
  //                       />
  //                       <div className="flex items-center gap-1">
  //                         <p className="text-xl font-semibold">
  //                           {user?.username}
  //                         </p>
  //                         {user && <EditProfile user={user} />}
  //                       </div>
  //                       <div className="flex items-center gap-1">
  //                         <p className="text-sm">
  //                           {displayAddress(wallet?.address)}
  //                         </p>
  //                         <Button
  //                           variant="ghost"
  //                           size="icon"
  //                           className="bg-gray-800"
  //                           onClick={() => {
  //                             navigator.clipboard.writeText(
  //                               wallet?.address || ""
  //                             );
  //                             toast({
  //                               title: "Address copied",
  //                             });
  //                           }}
  //                         >
  //                           <CopyIcon className="h-3 w-3" />
  //                         </Button>
  //                       </div>
  //                       <p className="text-sm">{account.displayBalance}</p>
  //                       <DialogClose asChild>
  //                         <Link
  //                           to={`/devs/${wallet?.address}`}
  //                           className="text-sm text-link"
  //                         >
  //                           View Profile
  //                         </Link>
  //                       </DialogClose>
  //                       <div className="mt-6 flex gap-2">
  //                         <Button
  //                           variant="destructive"
  //                           onClick={() => {
  //                             disconnect();
  //                           }}
  //                         >
  //                           Logout
  //                         </Button>
  //                         <DialogClose asChild>
  //                           <Button variant="outline">Close</Button>
  //                         </DialogClose>
  //                       </div>
  //                     </div>
  //                   </DialogContent>
  //                 </Dialog>
  //               );
  //             return (
  //               <div
  //                 onClick={openAccountModal}
  //                 className={cn(
  //                   "flex min-w-0 max-w-fit cursor-pointer items-center justify-center gap-1 rounded-sm bg-[#272A33] p-1",
  //                   {
  //                     "pl-2": !!account.displayBalance,
  //                   }
  //                 )}
  //               >
  //                 {!!account.displayBalance && (
  //                   <p className="text-nowrap text-[14px]">
  //                     {account.displayBalance.replace(" ETH", "")}&nbsp;Ξ
  //                   </p>
  //                 )}
  //                 <div className="min-w-0 rounded-sm bg-[#191B20] px-2 py-[2px]">
  //                   <p className="truncate text-[14px]">
  //                     {account.address.slice(0, 8)}...
  //                   </p>
  //                 </div>
  //               </div>
  //             );
  //           })()}
  //         </div>
  //       );
  //     }}
  //   </ConnectButton.Custom>
  // );
};
export default CustomConnectButton;
