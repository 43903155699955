import displayAddress from "@/Utils/displayAddress";
import { stonkClient } from "@/Utils/stonkClient";
import Back from "@/assets/Back";
import Info from "@/assets/InfoIcon";
import CopyTooltip from "@/components/CopyTooltip";
import { FormatEthAmount } from "@/components/FormatAmount";
import Image from "@/components/Image";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import useFollow from "@/hooks/useFollow";
import useWallet from "@/hooks/useWallet";
import { cn } from "@/lib/utils";
import { UserData } from "@/types/BaseTypes";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import EditProfile from "./EditProfile";
import Replies from "./Replies";
import Socials from "./Socials";
import TokensAllocated from "./TokensAllocated";
import TokensCreated from "./TokensCreated";
import TokensHeld from "./TokensHeld";

type Props = {
  userAddress: `0x${string}`;
};
const Profile = ({ userAddress }: Props) => {
  // const { userAddress = "0x" } = useParams<{
  //   userAddress: `0x${string}`;
  //   tab: string;
  // }>();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const wallet = useWallet();
  const follow = useFollow();

  // if username === address, show edit profile button
  const isOwner = userAddress === wallet?.address;

  const {
    data: user,
    isError,
    isLoading,
  } = useQuery<UserData>({
    queryKey: ["user", userAddress, "profile"],
    queryFn: async () => {
      const res = await stonkClient(`user/${userAddress}/profile/`, {
        params: { viewer_address: wallet?.address },
      });
      return res.data.data;
    },
  });

  // const { data: _reflections, refetch: refetchReflections } = useQuery({
  //   queryKey: ["user", userAddress, "reflections"],
  //   queryFn: async () => {
  //     const res = await stonkClient(`user/${userAddress}/reflections/`);
  //     return res.data.data as ReflectionData[];
  //   },
  //   enabled: isOwner,
  // });

  // const reflections = useMemo(() => {
  //   if (!_reflections) return { claimed: 0, claimable: 0, claimableTokens: [] };
  //   const refl = {
  //     claimed: _reflections.reduce(
  //       (acc, curr) => acc + curr.claimedReflectionAmount,
  //       0
  //     ),
  //     claimable: _reflections.reduce(
  //       (acc, curr) => acc + curr.claimableReflectionAmount,
  //       0
  //     ),
  //     claimableTokens: _reflections
  //       .filter((r) => r.claimableReflectionAmount > 0)
  //       .map((r) => r.tokenId),
  //   };
  //   return refl;
  // }, [_reflections]);

  // const { callContract } = useContract({
  //   toastTitle: "Claiming Reflection Rewards",
  //   onConfirmDelayed: () => refetchReflections(),
  // });

  // const handleClaimReflections = async () => {
  //   if (!wallet) return;
  //   const encodedParams = reflections.claimableTokens.map((tokenAddress) => {
  //     return encodeFunctionData({
  //       abi: RouterABI,
  //       functionName: "claimReflectionRewards",
  //       args: [tokenAddress, wallet?.address],
  //     });
  //   });
  //   await callContract("multicall", [encodedParams]);
  // };

  return (
    <div className="flex w-full flex-col gap-4 py-4">
      {/* Profile Header (excluding tabs) */}
      <div>
        <div className="cursor-pointer" onClick={() => navigate(-1)}>
          <Back />
        </div>
        <div className="mt-10 overflow-hidden rounded-full">
          {!!user && (
            <Image
              variant="profile"
              src={user.profile_image}
              alt="Profile image"
              isNsfw={user.is_nsfw || false}
              address={user.user_address}
              className="h-32 w-32 rounded-full"
            />
          )}
        </div>
        <div className="mb-3 flex items-center gap-4">
          <div className="flex flex-1 flex-col">
            <div className="mt-4 flex items-center gap-1 text-lg text-[#E7E9EA]">
              <div className="truncate">
                {isError ? (
                  "User not found"
                ) : (
                  <span>
                    {isLoading ? (
                      "Loading..."
                    ) : (
                      <span>
                        @{user?.username || displayAddress(user?.user_address)}
                      </span>
                    )}
                  </span>
                )}
              </div>
              {isOwner && !!user && (
                <EditProfile
                  user={{ ...user, image: user.profile_image ?? "" }}
                />
              )}
            </div>

            <span className="cursor-pointer">
              <CopyTooltip
                side="right"
                tooltipText="Copy"
                textToCopy={user?.user_address ?? ""}
              >
                <p className="font-inter w-fit text-[15px] font-normal text-[#BFDBFE]">
                  {displayAddress(user?.user_address)}
                </p>
              </CopyTooltip>
            </span>

            <p className="mt-4 text-[15px] text-subtext">{user?.bio || ""}</p>
          </div>
          <div className="flex flex-col">
            <div className="mb-2 flex items-center justify-center">
              <p className="font-roboto-mono font-bold text-accent underline decoration-accent decoration-1 underline-offset-4">
                {user?.score ? (user?.score || 0).toPrecision(3) : 0}
              </p>

              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger className="hidden sm:inline">
                    <Info className="ml-1 fill-accent" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="max-w-64">
                      A normalised score based on "(Total ETH Volume on your
                      tokens * total number of tokens launched) / profile age in
                      days"
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div>
              {isOwner ? (
                <Button
                  variant={"outline"}
                  size={"md"}
                  className="p-4 font-semibold"
                  onClick={() =>
                    setSearchParams(
                      (prev) => ({
                        ...prev,
                        tab: "verified-socials",
                      }),
                      { replace: true }
                    )
                  }
                >
                  Connect Social
                </Button>
              ) : (
                <Button
                  variant={user?.follows ? "outline" : "default"}
                  size={"md"}
                  className={cn("group rounded-full p-4", {
                    "bg-white": !user?.follows,
                  })}
                  onClick={() =>
                    follow({
                      followee_address: user?.user_address ?? "0x",
                      follow: !user?.follows,
                    })
                  }
                >
                  {user?.follows ? (
                    <>
                      <span className="group-hover:hidden">Following</span>
                      <span className="hidden group-hover:inline">
                        Unfollow
                      </span>
                    </>
                  ) : (
                    "Follow"
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
        <p className="font-roboto mb-3 text-[15px] font-normal text-subtext">
          Joined{" "}
          {!!user?.created_at &&
            new Date(user.created_at).toDateString().slice(4)}
        </p>
        <div className="flex flex-wrap gap-x-6 gap-y-1">
          <div className="font-roboto flex flex-row items-center gap-1 text-[14px] font-normal">
            <p className="text-[##E7E9EA]">{user?.following}</p>
            <p className="text-subtext">Following</p>
          </div>
          <div className="font-roboto flex flex-row items-center gap-1 text-[14px] font-normal">
            <p className="text-[##E7E9EA]">{user?.followers}</p>
            <p className="text-subtext">Followers</p>
          </div>
          <div className="font-roboto flex flex-row items-center gap-1 text-[14px] font-normal">
            <p className="text-[##E7E9EA]">{user?.total_likes}</p>
            <p className="text-subtext">Likes</p>
          </div>
          {/* <div className="flex flex-row items-center gap-1 font-normal text-[14px] font-roboto">
              <p className="text-[##E7E9EA]">0</p>
              <p className="text-subtext">Mentions</p>
            </div> */}
          <div className="font-roboto flex flex-row items-center gap-1 text-[14px] font-normal">
            <div className="text-[##E7E9EA]">
              <FormatEthAmount eth={user?.totalEarnings} />Ξ
            </div>
            <p className="text-subtext">Earnings</p>
          </div>
        </div>
        {/* {isOwner && (
          <div className="font-roboto flex flex-wrap gap-x-4 text-[14px] font-normal">
            <div>
              <span className="mr-1 text-subtext">Claimed Reflection</span>{" "}
              <span className="text-[##E7E9EA]">
                <FormatEthAmount eth={reflections.claimed} /> Ξ
              </span>
            </div>
            <div>
              <span className="text-subtext">Claimable Reflection</span>{" "}
              <span className="text-[##E7E9EA]">
                <FormatEthAmount eth={reflections.claimable} /> Ξ
              </span>
              {!!reflections.claimable && (
                <span
                  className="ml-3 cursor-pointer text-[#1D9BF0] underline"
                  onClick={handleClaimReflections}
                >
                  Claim
                </span>
              )}
            </div>
          </div>
        )} */}
      </div>
      {/* Tabs below profile */}
      <Tabs
        defaultValue="coins-held"
        className="w-full"
        onValueChange={(value) =>
          setSearchParams((prev) => ({ ...prev, tab: value }), {
            replace: true,
          })
        }
        value={searchParams.get("tab") || "coins-held"}
      >
        <div>
          <TabsList className="w-full justify-around">
            <TabsTrigger value="coins-held">
              Coins Held {!!user?.token_held && `(${user.token_held})`}
            </TabsTrigger>
            <TabsTrigger value="replies">
              Replies {!!user?.total_comments && `(${user.total_comments})`}
            </TabsTrigger>
            <TabsTrigger value="coins-created">
              Coins Created{" "}
              {!!user?.tokens_created && `(${user.tokens_created})`}
            </TabsTrigger>
            <TabsTrigger value="allocated">Allocated</TabsTrigger>
            {isOwner && (
              <TabsTrigger value="verified-socials">
                Verified Socials
              </TabsTrigger>
            )}
          </TabsList>
        </div>
        <div className="pt-3">
          <TabsContent value="coins-held">
            <TokensHeld userAddress={userAddress} />
          </TabsContent>
          <TabsContent value="replies">
            <Replies userAddress={userAddress} />
          </TabsContent>
          <TabsContent value="coins-created">
            <TokensCreated userAddress={userAddress} />
          </TabsContent>
          <TabsContent value="allocated">
            <TokensAllocated
              userAddress={userAddress}
              isOwner={isOwner}
              setTab={(value) =>
                setSearchParams((prev) => ({ ...prev, tab: value }), {
                  replace: true,
                })
              }
            />
          </TabsContent>
          {isOwner && (
            <TabsContent value="verified-socials">
              <Socials userAddress={userAddress} />
            </TabsContent>
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default Profile;
