import TimeElapsed from "@/components/TimeElapsed";
import { Button } from "@/components/ui/button";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import { cn } from "@/lib/utils";
import { stonkClient } from "@/Utils/stonkClient";
import React, { useState } from "react";

import Image from "@/components/Image";
import { useToast } from "@/components/ui/use-toast";
import useWallet from "@/hooks/useWallet";
import { useMutation } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import ReplyDialog from "./ReplyDialog";

// Generate type of following

type Reply = {
  id: number;
  comment: string;
  created_at: string;
  user_address: `0x${string}`;
  username: string;
  user_profile_image: string;
  user_image_is_nsfw: boolean;
  comment_image: string;
  comment_image_is_nsfw: boolean;
  total_likes: number;
  liked: boolean;
};

const Replies = ({ tokenAddress }: { tokenAddress: string }) => {
  const wallet = useWallet();

  const {
    data: replies,
    loadMoreRef,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteScroll({
    queryKey: ["token", tokenAddress, "comments"],
    queryFn: async ({ pageParam }) => {
      const res = await stonkClient(`token/${tokenAddress}/comments/`, {
        params: {
          viewer_address: wallet?.address,
          page: pageParam,
        },
      });
      return res.data.data as Reply[];
    },
    refetchInterval: import.meta.env.VITE_DISABLE_POOLING ? undefined : 1000,
  });

  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <Button
        id="reply-button"
        onClick={() => {
          setOpen(!open);
        }}
        className="w-full justify-start bg-b1 text-left text-secondary-foreground hover:text-primary-foreground"
      >
        Post a reply...
      </Button>
      <ReplyDialog open={open} setOpen={setOpen} tokenAddress={tokenAddress} />

      {!!replies &&
        replies.pages.map((group, i) => (
          <React.Fragment key={i}>
            {group.map((reply) => (
              <ReplyCard key={reply.id} reply={reply} />
            ))}
          </React.Fragment>
        ))}

      <div ref={loadMoreRef} id="load-more" style={{ height: 1 }} />
      {isFetchingNextPage && <p>Loading more...</p>}
      {isLoading && !isFetchingNextPage && <p>Loading...</p>}
      {!!replies &&
        (replies.pages.length === 0 || replies?.pages[0].length === 0) && (
          <p>Nothing here yet</p>
        )}
    </div>
  );
};

export { Replies };
interface ReplyCardProps {
  reply: Reply;
}
const ReplyCard: React.FC<ReplyCardProps> = ({ reply }) => {
  const { comment, created_at, username } = reply;
  const [enlarged, setEnlarged] = useState(false);

  const wallet = useWallet();
  const { toast } = useToast();
  const [currentLikedStatus, setCurrentLikedStatus] = useState(reply.liked);
  const likesCount =
    reply.liked === currentLikedStatus
      ? reply.total_likes
      : currentLikedStatus
        ? reply.total_likes + 1
        : reply.total_likes - 1;

  const likeReply = useMutation({
    mutationKey: ["comments", "likes", wallet?.address],
    mutationFn: async (like: boolean) => {
      const res = await stonkClient.post(`comment/like/`, null, {
        params: {
          user_address: wallet?.address,
          user_signature: wallet?.getSignature(),
          comment_id: reply.id,
          like,
        },
      });
      return res.data.data;
    },
  });

  const handleLike = async () => {
    if (!wallet) {
      toast({
        title: "Login to like",
        description: "You need to login to like this comment",
      });
      return;
    }
    await likeReply.mutateAsync(!currentLikedStatus);
    setCurrentLikedStatus(!currentLikedStatus);
  };

  return (
    <div className="flex flex-col gap-3 rounded-md bg-b1 p-3">
      <div className="flex items-center text-sm">
        <Link to={`/devs/${reply.user_address}`}>
          <Image
            variant="profile"
            className="h-6 w-6 rounded-full"
            src={reply.user_profile_image}
            isNsfw={reply.user_image_is_nsfw}
            address={reply.user_address}
            alt="Profile image"
          ></Image>
        </Link>
        <Link to={`/devs/${reply.user_address}`}>
          <p className="px-1 text-md hover:underline">@{username}</p>
        </Link>
        <p className="mx-2 text-secondary-foreground">
          <TimeElapsed eventTime={new Date(created_at)} /> ago
        </p>
        <button className="flex items-center text-secondary-foreground hover:text-red">
          {" "}
          <HeartButton
            className={cn({ "fill-red text-red": currentLikedStatus })}
            onClick={handleLike}
          />
          &nbsp;&nbsp;{likesCount}
        </button>
      </div>
      <div
        className={cn("flex flex-wrap gap-3 lg:flex-nowrap", {
          "items-start": !enlarged,
          "flex-col justify-start": enlarged,
        })}
      >
        {!!reply.comment_image && (
          <div
            className="w-fit min-w-32"
            onClick={() => setEnlarged((ee) => !ee)}
          >
            <Image
              variant="comment"
              className={cn(
                "max-h-[500px] cursor-pointer rounded-none object-contain",
                {
                  "w-32": !enlarged,
                  "w-full": enlarged,
                }
              )}
              src={reply.comment_image}
              isNsfw={reply.comment_image_is_nsfw}
              alt="Comment image"
            />
          </div>
        )}
        <p className="text-md text-primary-foreground">{comment}</p>
      </div>
    </div>
  );
};

export default ReplyCard;

const HeartButton = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-heart"
      {...props}
    >
      <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z"></path>
    </svg>
  );
};
