import { uploadImageFromForm } from "@/Utils/ImageUploadHelpers";
import { stonkClient } from "@/Utils/stonkClient";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input, TextArea } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import useWallet from "@/hooks/useWallet";
import { TokenData } from "@/types/BaseTypes";
import {
  Cross1Icon,
  InfoCircledIcon,
  Pencil1Icon,
} from "@radix-ui/react-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import VerifyWebsite from "./Verification/Website";

type SettingsDialogProps = {
  tokenId: `0x${string}`;
  buttonClassName?: string;
  buttonText?: string;
  tokenData: TokenData;
};

type Settings = {
  twitter?: string;
  telegram?: string;
  website?: string;
  chat_access_balance?: number;
  preview_images?: string[];
  short_description?: string;
  description?: string;
  twitter_verification_url?: string;
};

const validationSchema: Yup.ObjectSchema<Settings> = Yup.object().shape({
  twitter: Yup.string().url("Invalid URL"),
  twitter_verification_url: Yup.string().url("Invalid URL"),
  telegram: Yup.string().url("Invalid URL"),
  website: Yup.string().url("Invalid URL"),
  chat_access_balance: Yup.number()
    .min(0, "Must be a positive number")
    .max(1e17, "Cannot be greater than total supply")
    .required("Required"),
  preview_images: Yup.array()
    .of(Yup.string().required().url("Invalid URL"))
    .required(),
  short_description: Yup.string().max(100).required("Required"),
  description: Yup.string().max(1000),
});

const SettingsDialog = ({
  tokenId,
  tokenData,
  buttonClassName,
  buttonText,
}: SettingsDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wallet = useWallet();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const settings: Settings = {
    twitter: tokenData.twitter,
    telegram: tokenData.telegram,
    website: tokenData.website,
    chat_access_balance: tokenData.chat_access_balance,
    preview_images: tokenData.preview_images,
    short_description: tokenData.short_description,
    description: tokenData.description,
    twitter_verification_url: tokenData.twitter_verification_url,
  };

  useEffect(() => {
    if (!isOpen) {
      setFilesToUpload([]);
    }
  }, [isOpen]);

  const { mutate: updateSettings } = useMutation({
    mutationFn: (values: Partial<Settings>) => {
      return stonkClient.post(`/token/${tokenId}/settings/`, null, {
        params: {
          user_address: wallet?.address,
          user_signature: wallet?.getSignature(),
          ...values,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["token", tokenId] });
      toast({
        title: "Settings updated successfully",
      });
    },
    onError: () => {
      toast({
        title: "Error updating settings",
      });
    },
  });

  const handleSave = async (values: typeof settings) => {
    const changedValues = Object.keys(values).reduce(
      (acc, key) => {
        const typedKey = key as keyof Settings;
        if (typedKey === "preview_images") {
          if (
            JSON.stringify(values[typedKey]) !==
            JSON.stringify(settings[typedKey])
          ) {
            acc[typedKey] = values[typedKey] as string[];
          }
        } else if (values[typedKey] !== settings[typedKey]) {
          // @ts-ignore
          acc[typedKey] = values[typedKey];
        }
        return acc;
      },
      {} as Partial<typeof settings>
    );
    if (Object.keys(changedValues).length === 0 && filesToUpload.length === 0) {
      toast({
        title: "No changes made",
      });
      return;
    }
    if (filesToUpload.length > 0) {
      toast({
        title: "Uploading images...",
      });
      const images = await Promise.all(
        filesToUpload.map((file) => uploadImageFromForm(file))
      );
      changedValues.preview_images = [
        ...(settings.preview_images || []),
        ...images,
      ];
    }
    updateSettings(changedValues);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="md" className={buttonClassName}>
          <Pencil1Icon className="mr-1 h-4 w-4" /> {buttonText || "Edit"}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>Edit Settings</DialogTitle>
          <DialogDescription>
            Make changes to your token settings here. Click save when you're
            done.
          </DialogDescription>
        </DialogHeader>
        <Formik
          initialValues={settings}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <Form className="">
              <div className="flex flex-wrap gap-4 lg:flex-nowrap">
                <div className="flex w-1/2 flex-auto flex-col gap-3 py-4">
                  <div>
                    <Label htmlFor="description">Description</Label>
                    <TextArea
                      id="description"
                      name="description"
                      type="text"
                      className=""
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description && (
                      <div className="text-sm text-red">
                        {errors.description}
                      </div>
                    )}
                  </div>
                  {(
                    [
                      "short_description",
                      "website",
                      "twitter",
                      "telegram",
                    ] as const
                  ).map((field) => (
                    <div key={field}>
                      <Label htmlFor={field} className="capitalize">
                        {field.split("_").join(" ")}
                      </Label>
                      <Input
                        id={field}
                        name={field}
                        className=""
                        value={values[field]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors[field] && touched[field] && (
                        <div className="text-sm text-red">{errors[field]}</div>
                      )}
                    </div>
                  ))}
                  <div>
                    <Label htmlFor="chat_access_balance">
                      Chat Access Balance
                    </Label>
                    <Input
                      id="chat_access_balance"
                      name="chat_access_balance"
                      type="number"
                      className=""
                      value={(values.chat_access_balance ?? 0) / 1e8}
                      onChange={(e) =>
                        setFieldValue(
                          "chat_access_balance",
                          Number(e.target.value) * 1e8
                        )
                      }
                      onBlur={handleBlur}
                    />
                    {errors.chat_access_balance &&
                      touched.chat_access_balance && (
                        <div className="text-sm text-red">
                          {errors.chat_access_balance}
                        </div>
                      )}
                  </div>
                  <p>Verification</p>
                  <div className="flex items-center gap-4">
                    <span>Website</span>
                    {tokenData.verified ? (
                      <span className="text-green">Verified</span>
                    ) : (
                      <span className="text-md text-red">Not verified</span>
                    )}
                    {!tokenData.verified && <VerifyWebsite tokenId={tokenId} />}
                  </div>
                  <div className="flex items-center gap-4">
                    <span>Twitter/X</span>
                    {tokenData.twitter_verified ? (
                      <span className="text-green">Verified</span>
                    ) : (
                      <TooltipProvider delayDuration={0}>
                        <Tooltip>
                          <TooltipTrigger>
                            <span className="text-nowrap text-md text-red">
                              Not verified{" "}
                              <InfoCircledIcon className="inline-block h-3 w-3" />
                            </span>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>
                              Verification is done manually. It may take some
                              time.
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                    {!tokenData.twitter_verified && (
                      <Input
                        id="twitter_verification_url"
                        name="twitter_verification_url"
                        className=""
                        value={values.twitter_verification_url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </div>
                </div>
                <div className="flex w-1/2 flex-auto flex-col gap-3 py-4">
                  <Label htmlFor="preview_images">Preview Images</Label>
                  <div className="flex flex-wrap gap-2">
                    {values.preview_images?.map((image, index) => (
                      <div key={"saved" + index} className="relative">
                        <img
                          src={image}
                          alt={`preview ${index}`}
                          className="w-32 object-cover"
                        />
                        <button
                          type="button"
                          className="absolute -right-2 -top-2 rounded-full bg-red p-0.5 text-white"
                          onClick={() => {
                            const newImages = values.preview_images?.filter(
                              (_, i) => i !== index
                            );
                            setFieldValue("preview_images", newImages);
                          }}
                        >
                          <Cross1Icon className="h-3 w-3" />
                        </button>
                      </div>
                    ))}
                    {filesToUpload.map((image, index) => (
                      <div key={"to_upload" + index} className="relative">
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`preview ${index}`}
                          className="w-32 object-cover"
                        />
                        <button
                          type="button"
                          className="absolute -right-2 -top-2 rounded-full bg-red p-0.5 text-white"
                          onClick={() => {
                            const newImages = filesToUpload.filter(
                              (_, i) => i !== index
                            );
                            setFilesToUpload(newImages);
                          }}
                        >
                          <Cross1Icon className="h-3 w-3" />
                        </button>
                      </div>
                    ))}
                  </div>
                  <input
                    id="preview_images"
                    name="preview_images"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    ref={fileInputRef}
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files) {
                        const newImages = [...filesToUpload];
                        for (let i = 0; i < files.length; i++) {
                          newImages.push(files[i]);
                        }
                        setFilesToUpload(newImages);
                      }
                    }}
                  />
                  <Button
                    variant="outline"
                    type="button"
                    size="md"
                    className="w-fit"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    Upload Images
                  </Button>
                </div>
              </div>
              <DialogFooter className="flex justify-end gap-4">
                <Button variant="outline" onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit">Save changes</Button>
              </DialogFooter>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;
