import { stonkClient } from "@/Utils/stonkClient";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import useWallet from "@/hooks/useWallet";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { MdOutlineVerified } from "react-icons/md";

type VerifyWebsiteProps = {
  tokenId: `0x${string}`;
};

const VerifyWebsite = ({ tokenId }: VerifyWebsiteProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wallet = useWallet();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { mutate: verifyWebsite, isPending } = useMutation({
    mutationFn: () => {
      return stonkClient.post(`/token/${tokenId}/verify/website`, null, {
        params: {
          user_address: wallet?.address,
          user_signature: wallet?.getSignature(),
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["token", tokenId] });
      toast({
        title: "Website verified successfully",
      });
    },
    onError: () => {
      toast({
        title: "Error verifying website",
      });
    },
  });

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="md" className="px-4">
          Verify
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            Verify Website
            <MdOutlineVerified className="inline text-accent-green" />
          </DialogTitle>
          <DialogDescription>
            Verify your website to unlock the verified badge.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4 text-md">
          <h2 className="text-base font-semibold">
            Website Verification Methods
          </h2>
          <p>
            Please choose one of the following methods to verify your website
            ownership:
          </p>

          <div className="space-y-2">
            <h3 className="text-md font-medium">1. DNS TXT Record</h3>
            <p>
              Add a TXT record to your domain's DNS settings with the following
              value:
            </p>
            <code className="block rounded bg-gray-800 p-2">
              productdotfun-{tokenId}
            </code>
          </div>

          <div className="space-y-2">
            <h3 className="text-md font-medium">2. Meta Tag</h3>
            <p>
              Add the following meta tag to the <code>&lt;head&gt;</code>{" "}
              section of your website's home page:
            </p>
            <code className="block rounded bg-gray-800 p-2">
              &lt;meta name="productdotfun" content="{tokenId}"&gt;
            </code>
          </div>

          <div className="space-y-2">
            <h3 className="text-md font-medium">3. HTML File</h3>
            <p>
              Create an HTML file named <code>{tokenId}.html</code> in the root
              directory of your website with the following content:
            </p>
            <code className="block rounded bg-gray-800 p-2">
              productdotfun={tokenId}
            </code>
          </div>

          <p className="text-md text-secondary-foreground">
            After implementing one of these methods, click the Verify button
            below to complete the verification process.
          </p>
        </div>
        <Button
          size="md"
          onClick={() => verifyWebsite()}
          disabled={isPending}
          isLoading={isPending}
        >
          Verify
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default VerifyWebsite;
