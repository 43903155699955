import { ReplyValidationSchema } from "@/Schemas/CoinCreateSchema";
import { uploadImageFromForm } from "@/Utils/ImageUploadHelpers";
import { stonkClient } from "@/Utils/stonkClient";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "@/components/ui/dialog";
import { HandeledTextArea, Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import useWallet from "@/hooks/useWallet";
import { cn } from "@/lib/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Field, Formik } from "formik";
import { useState } from "react";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tokenAddress: string;
};

const ReplyDialog = ({ open, setOpen, tokenAddress }: Props) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const wallet = useWallet();
  const { toast } = useToast();

  const replyMutation = useMutation({
    mutationKey: ["token", tokenAddress, "comments"],
    mutationFn: async ({
      image,
      comment,
    }: {
      image: File | null;
      comment: string;
    }) => {
      const url = image ? await uploadImageFromForm(image) : undefined;
      await stonkClient.post("comment/", null, {
        params: {
          user_address: wallet?.address,
          token_address: tokenAddress,
          comment,
          image: url,
          user_signature: wallet?.getSignature(),
        },
      });
    },
    onSuccess: () => {
      toast({
        title: "Comment added successfully",
      });
      queryClient.invalidateQueries({
        queryKey: ["token", tokenAddress, "comments"],
      });
      setLoading(false);
      setOpen(false);
    },
    onError: (e) => {
      toast({
        title: "Error",
        description: e.message,
      });
      setLoading(false);
    },
  });

  return (
    <Dialog open={open} onOpenChange={(e) => setOpen(e)}>
      <DialogContent>
        <DialogTitle>Create a comment</DialogTitle>
        <DialogDescription>Comment on this post</DialogDescription>
        <Formik
          initialValues={{
            comment: "",
            image: null as File | null,
          }}
          onSubmit={(v) => {
            setLoading(true);
            replyMutation.mutateAsync({
              image: v.image,
              comment: v.comment,
            });
          }}
          validationSchema={ReplyValidationSchema}
        >
          {(props) => {
            return (
              <form
                className="flex flex-col gap-3"
                onSubmit={props.handleSubmit}
              >
                <HandeledTextArea
                  className="w-full"
                  placeholder="Comment"
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.comment}
                  name="comment"
                  error={
                    props.errors.comment && props.touched.comment
                      ? props.errors.comment
                      : null
                  }
                />
                <Field
                  name="image"
                  type="file"
                  // validate={imageValidate}
                  render={() => {
                    return (
                      <div className="col-span-2 w-full">
                        <label
                          htmlFor="Image"
                          className={cn(
                            "block w-full cursor-pointer rounded-md border-[2px] border-dashed border-secondary-foreground px-3 py-1 text-center text-secondary",
                            props.errors.image &&
                              props.touched.image &&
                              "border-red"
                          )}
                        >
                          <div
                            className={cn(
                              "flex w-full items-center justify-center gap-2",
                              !(props.values.image && !props.errors.image) &&
                                "hidden"
                            )}
                          >
                            Got the image{" "}
                            <img
                              src=""
                              id="image-preview"
                              className="h-[26px] w-[26px] rounded-sm"
                            />
                          </div>
                          <p
                            className={
                              props.values.image && !props.errors.image
                                ? "hidden"
                                : ""
                            }
                          >
                            Upload image
                          </p>
                        </label>
                        <Input
                          id="Image"
                          className="absolute z-[-1] w-1 border-2 opacity-0"
                          placeholder="Image"
                          type="file"
                          name="image"
                          onChange={(e) => {
                            if (e.target.files && document) {
                              const url = URL.createObjectURL(
                                e.target.files[0]
                              );
                              document
                                ?.getElementById("image-preview")
                                ?.setAttribute("src", url);
                              props.setFieldValue("image", e.target.files[0]);
                            }
                          }}
                          onBlur={props.handleBlur}
                        />
                        {props.errors.image && props.touched.image ? (
                          <p className="text-red"> {props.errors.image}</p>
                        ) : null}
                      </div>
                    );
                  }}
                ></Field>
                {!wallet && (
                  <p className="text-center text-red">
                    Login to post a comment
                  </p>
                )}
                <div className="flex">
                  <Button
                    type="submit"
                    className="m-auto"
                    isLoading={loading}
                    disabled={!wallet}
                  >
                    Post the comment
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ReplyDialog;
