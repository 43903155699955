import { usePrivy } from "@privy-io/react-auth";
import Cookies from "js-cookie";

const useWallet = () => {
  const { user } = usePrivy();
  const getSignature = () => Cookies.get("privy-id-token");
  return user && user.wallet?.address
    ? { address: user.wallet?.address as `0x${string}`, getSignature }
    : null;
};

export default useWallet;
