import AppStoreIcon from "@/assets/appStore.png";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
} from "@/components/ui/sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";
import useWallet from "@/hooks/useWallet";
import { ExplorerData } from "@/types/BaseTypes";
import { stonkClient } from "@/Utils/stonkClient";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CreateAToken from "../CreateAToken";
import Landing from "../Landing";
import Profile from "../Profile";
import TokenPage from "../Token";
import CoinsTab from "./CoinsTab";
import IdeasTab from "./IdeasTab";
import PeopleTab from "./PeopleTab";

const index = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const wallet = useWallet();

  const [, tab, id] = location.pathname.split("/");
  console.log(tab, id);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640); // 640px is the 'sm' breakpoint in Tailwind
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const { data: ExplorerData } = useQuery({
    queryKey: ["explorer"],
    queryFn: async () => {
      const res = await stonkClient.get("explorer/", {
        params: { viewer_address: wallet?.address },
      });
      return res.data.data as ExplorerData;
    },
    refetchInterval: import.meta.env.VITE_DISABLE_POOLING ? undefined : 1000,
  });

  return (
    <div className="flex h-full">
      {isMobile && !tab ? (
        <Landing />
      ) : (
        <Tabs
          value={tab || "apps"}
          className="scroll-hidden mr-4 h-full w-1/3 flex-auto overflow-y-scroll sm:mr-0 sm:flex-none lg:w-1/4 2xl:w-1/5"
        >
          <div className="sticky top-0 bg-black/80">
            <TabsList className="flex justify-center">
              <TabsTrigger value="apps" asChild>
                <Link to="/apps" className="flex items-center gap-1">
                  <img
                    src={AppStoreIcon}
                    className="h-5 w-5"
                    alt="App Store logo"
                  />
                  <span>Apps</span>
                </Link>
              </TabsTrigger>
              <TabsTrigger value="devs" asChild>
                <Link to="/devs" className="flex items-center gap-1">
                  <span className="text-lg leading-none">🧑‍💻</span>
                  <span>Devs</span>
                </Link>
              </TabsTrigger>
              {/* <TabsTrigger value="ideas" asChild>
                <Link to="/ideas">Ideas</Link>
              </TabsTrigger> */}
            </TabsList>
          </div>
          <div className="mb-8 h-fit min-h-0">
            <TabsContent value="apps">
              <CoinsTab
                ultraStonk={ExplorerData?.ultra_stonk}
                isMobile={isMobile}
              />
            </TabsContent>
            <TabsContent value="devs">
              <PeopleTab
                kingOfStonk={ExplorerData?.king_of_stonks}
                isMobile={isMobile}
              />
            </TabsContent>
            <TabsContent value="ideas">
              <IdeasTab isMobile={isMobile} />
            </TabsContent>
          </div>
        </Tabs>
      )}
      {isMobile ? (
        <Sheet
          open={!!id}
          onOpenChange={(open) => !open && navigate(`/${tab}`)}
        >
          <SheetContent
            side="bottom"
            className="h-[100dvh] w-screen overflow-y-scroll"
          >
            <SheetTitle className="hidden"></SheetTitle>
            <SheetDescription className="hidden"></SheetDescription>
            {/* <div className="h-fit min-h-full flex-auto justify-center overflow-y-scroll px-4"> */}
            {tab === "apps" &&
              (id === "create" ? (
                <CreateAToken />
              ) : (
                <TokenPage tokenId={id as `0x${string}`} />
              ))}
            {tab === "devs" && <Profile userAddress={id as `0x${string}`} />}
            {tab === "ideas" && <CreateAToken />}
            {/* </div> */}
          </SheetContent>
        </Sheet>
      ) : (
        <div className="h-full flex-auto justify-center overflow-y-scroll px-4">
          {tab === "apps" &&
            (!id ? (
              <Landing />
            ) : id === "create" ? (
              <CreateAToken />
            ) : (
              <TokenPage tokenId={id as `0x${string}`} />
            ))}
          {tab === "devs" &&
            (!id ? (
              <Landing />
            ) : (
              <div className="w-full max-w-3xl">
                <Profile userAddress={id as `0x${string}`} />
              </div>
            ))}
          {tab === "ideas" && <CreateAToken />}
          {tab === "" && <Landing />}
        </div>
      )}
      <Link
        to="/apps/create"
        className="fixed bottom-4 right-4 z-50 rounded-full shadow-lg sm:hidden"
      >
        <Button
          variant="gradient"
          size="lg"
          className="h-14 w-14 rounded-full p-0 text-3xl"
        >
          +
        </Button>
      </Link>
    </div>
  );
};

export default index;
