import { UserSignInResponse } from "@/types/BaseTypes";
import { stonkClient } from "@/Utils/stonkClient";
import { usePrivy } from "@privy-io/react-auth";
import { useQuery } from "@tanstack/react-query";
import useWallet from "./useWallet";

const useUser = () => {
  const wallet = useWallet();
  const { authenticated } = usePrivy();

  return useQuery({
    queryKey: ["user", wallet?.address],
    queryFn: async () => {
      const res = await stonkClient.post("signIn/", null, {
        params: {
          user_address: wallet?.address,
          user_signature: wallet?.getSignature(),
        },
      });
      return res.data.data as UserSignInResponse;
    },
    enabled: !!authenticated && !!wallet,
  });
};

export default useUser;
