import BondingCurveProgress from "@/components/BondingCurveProgress";
import CopyTooltip from "@/components/CopyTooltip";
import Image from "@/components/Image";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useEthConversion } from "@/hooks/useEthPrice";
import useWallet from "@/hooks/useWallet";
import { TokenData } from "@/types/BaseTypes";
import displayAddress from "@/Utils/displayAddress";
import { getGraduationProgressByMcap } from "@/Utils/PlatformMath/getTokenomics";
import { BiSolidCheckShield } from "react-icons/bi";
import { FaTelegramPlane } from "react-icons/fa";
import { FiTwitter, FiUsers } from "react-icons/fi";
import { IoWarning } from "react-icons/io5";
import { MdOutlineVerified } from "react-icons/md";
import { Link } from "react-router-dom";
import SettingsDialog from "./SettingsDialog";

type Props = {
  token: TokenData;
};
const InfoMain = ({ token }: Props) => {
  const wallet = useWallet();
  const { compactUSD } = useEthConversion();

  return (
    <div>
      <div className="flex">
        <Image
          variant="token"
          src={token.token_image}
          alt={token.name}
          isNsfw={token.token_image_is_nsfw}
          className="h-32 w-32 rounded-2xl object-cover"
        />
        <div className="ml-2 flex w-full min-w-0 flex-col">
          <div className="flex flex-wrap gap-x-2">
            <h1 className="truncate text-lg font-semibold text-[#e7e9ea]">
              {token.name}
            </h1>
            {/* {variant === "descriptive" && token?.created_at && (
                <span className="ml-auto text-sm text-subtext">
                  <TimeElapsed eventTime={new Date(token?.created_at * 1000)} />
                </span>
              )} */}
            <h2 className="flex items-center gap-2 text-lg text-secondary-foreground">
              ${token.ticker}
              {token.verified && (
                <MdOutlineVerified className="inline text-accent-green" />
              )}
            </h2>
          </div>
          <p className="text-sm text-[#bfdbfe]">
            Created by
            <Link to={`/devs/${token.creator_address}`}>
              <span className="text-link"> @{token.creator_username}</span>
            </Link>
          </p>

          <p className="truncate text-wrap text-md leading-snug text-white [-webkit-box-orient:vertical] [-webkit-line-clamp:2] [display:-webkit-box]">
            {token.short_description}
          </p>
          <div className="mt-2 flex items-center justify-between">
            <div className="flex flex-1 flex-wrap gap-x-2 text-sm text-accent-green">
              <span className="text-sm">
                <FiUsers className="inline-block text-md" />{" "}
                {token.total_holders}
              </span>
              <span>
                <span className="font-semibold">Mkt Cap</span>{" "}
                {compactUSD((token.marketCap || 0) / 1e18)}{" "}
              </span>
              <span>
                <span className="font-semibold">CA</span>{" "}
                <CopyTooltip
                  textToCopy={token.id}
                  tooltipText="Copy"
                  side="top"
                >
                  <span className="cursor-pointer">
                    {displayAddress(token.id)}
                  </span>
                </CopyTooltip>
              </span>

              {!!token.isUltraStonk && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <span className="text-sm">👑</span>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Ultra Stonk</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              {!!token.graduationTokenAmount &&
                !!token.soldInBondingCurve &&
                token.soldInBondingCurve >= token.graduationTokenAmount && (
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <span className="text-sm">🦄</span>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Coin graduated</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
            </div>
          </div>
          <div className="mt-1 w-full max-w-72 pr-6">
            <BondingCurveProgress
              graduationAmount={token.graduationTokenAmount}
              currentAmount={token.soldInBondingCurve}
            />
            <p className="text-sm text-accent-green">
              {Math.round(
                getGraduationProgressByMcap(
                  token.soldInBondingCurve,
                  token.graduationTokenAmount
                )
              )}
              % funded
            </p>
          </div>
        </div>
        <div className="flex flex-col items-end gap-2">
          {wallet?.address === token.creator_address && (
            <SettingsDialog
              tokenId={token.id}
              buttonClassName="min-w-24 px-4"
              buttonText="Edit"
              tokenData={token}
              // verified={token.verified}
              // settings={{
              //   description: token.description,
              //   short_description: token.short_description,
              //   twitter: token.twitter,
              //   telegram: token.telegram,
              //   website: token.website,
              //   chat_access_balance: token.chat_access_balance,
              //   preview_images: token.preview_images,
              // }}
            />
          )}
          {token?.website && (
            <a href={token?.website} target="_blank">
              <Button size="md" className="min-w-24 px-4">
                Visit
              </Button>
            </a>
          )}
          <div className="flex w-full justify-around gap-2">
            {token?.twitter && (
              <a href={token.twitter} target="_blank">
                <Button variant="outline" size="icon" className="relative">
                  <FiTwitter />
                  {token.twitter_verified ? (
                    <BiSolidCheckShield className="absolute -right-2 -top-2 text-accent-green" />
                  ) : (
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger>
                          <IoWarning className="absolute -right-2 -top-2 text-accent" />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Twitter account not verified</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </Button>
              </a>
            )}
            {token?.telegram && (
              <a href={token.telegram} target="_blank">
                <Button variant="outline" size="icon" className="relative">
                  <FaTelegramPlane />
                </Button>
              </a>
            )}
          </div>
        </div>
      </div>
      <p className="mt-2 truncate text-wrap text-[15px] leading-snug text-[#9ca3af] [-webkit-box-orient:vertical] [-webkit-line-clamp:4] [display:-webkit-box]">
        {token.description}
      </p>
    </div>
  );
};

export default InfoMain;
