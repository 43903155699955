import camelotLogo from "@/assets/camelotLogo.png";
import { getGraduationProgressByMcap } from "@/Utils/PlatformMath/getTokenomics";
import { Progress } from "./ui/progress";

type Props = {
  graduationAmount: number;
  currentAmount: number;
};

const BondingCurveProgress = ({ graduationAmount, currentAmount }: Props) => {
  const progress = getGraduationProgressByMcap(currentAmount, graduationAmount);

  return (
    <div className="relative w-full">
      <Progress
        innerClassName="bg-dark-green"
        className="h-1.5 border border-accent-green bg-transparent"
        value={progress}
      />
      <img src={camelotLogo} className="absolute -right-2 w-4 -translate-y-4" />
      <div className="absolute left-1/2 w-4 -translate-x-1/2 -translate-y-4 text-base">
        💎
      </div>
    </div>
  );
};

export default BondingCurveProgress;
