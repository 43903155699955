import HowItWorksIcon from "@/assets/HowItWorksIcon";
import Logo from "@/assets/logo.png";
import useHowItWorks from "@/hooks/useHowItWorks";
import useWallet from "@/hooks/useWallet";
import { Link } from "react-router-dom";
import CustomConnectButton from "./ConnectButton";
import { Faucet } from "./Faucet";
import LiveActivity from "./LiveActivity";
import { Button } from "./ui/button";

const TopNav = () => {
  const { openHowItWorks } = useHowItWorks();
  const wallet = useWallet();

  return (
    <div className="mr-4">
      <div className="relative flex items-center gap-2">
        {/* <Sheet>
        <SheetTrigger>
          <div>
            <HamburgerMenuIcon className="h-6 w-6" />
          </div>
        </SheetTrigger>
        <SheetContent side="left" className="py-0">
          <SheetTitle className="hidden">Sidebar menu</SheetTitle>
          <SheetDescription className="hidden">
            Sidebar navigation menu
          </SheetDescription>
          <Sidebar withSheetClose />
        </SheetContent>
      </Sheet> */}
        <Link to="/" className="min-w-fit">
          <img
            src={Logo}
            alt="Logo"
            className="h-8 w-8 scale-125 rounded-full"
          />
        </Link>
        <a
          href="https://rose-kitten-a1c.notion.site/FAQs-c7d2f63e321340e880e702d42aaf1021"
          target="_blank"
        >
          <div
            className="flex cursor-pointer gap-1"
            // onClick={openHowItWorks}
          >
            <HowItWorksIcon height={20} width={20} />
            <span className="hidden text-nowrap text-md lg:inline">
              How it works
            </span>
          </div>
        </a>
        <div className="hidden min-w-0 flex-auto sm:block">
          <LiveActivity />
        </div>
        {/* <div
        className="ml-auto flex w-fit flex-none cursor-pointer items-center gap-1"
        onClick={openAccountModal}
      >
        <CustomConnectButton />
        <Image
          src={"/MemeHolder.png"}
          address={wallet?.address || "0x"}
          isNsfw={false}
          variant="profile"
          className="h-6 w-6 rounded-full"
        />
        <p className="text-md">Anshit01</p>
        <ChevronDownIcon className="h-2 w-2" />
        </div> */}
        {wallet && <Faucet />}
        <Link to="/apps/create" className="ml-auto">
          <Button
            // size="md"
            className="flex-none px-4 hover:brightness-125"
          >
            Launch your App
          </Button>
        </Link>
        <div className="flex w-fit flex-none gap-2">
          {/* <CoinbaseCreateWallet /> */}
          <CustomConnectButton />
        </div>
      </div>
      <div className="mt-2 w-full min-w-0 sm:hidden">
        <LiveActivity />
      </div>
    </div>
  );
};

export default TopNav;
