import { uploadImageFromForm } from "@/Utils/ImageUploadHelpers";
import { stonkClient } from "@/Utils/stonkClient";
import Edit from "@/assets/Edit";
import Image from "@/components/Image";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import useWallet from "@/hooks/useWallet";
import { UserSignInResponse } from "@/types/BaseTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { FaRegEdit } from "react-icons/fa";

type UpdateProfileParams = {
  user_name: string;
  image?: string;
  bio: string;
  user_address: `0x${string}`;
  user_signature: string | undefined;
};

const EditProfile = ({ user }: { user: UserSignInResponse }) => {
  const [formState, setFormState] = useState({
    username: user.username,
    usernameError: "",
    bio: user.bio,
    bioError: "",
    image: user.image,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const selectedImageUrl = useMemo(
    () => (selectedFile ? URL.createObjectURL(selectedFile) : null),
    [selectedFile]
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const wallet = useWallet();
  const queryClient = useQueryClient();

  const updateProfile = useMutation({
    mutationKey: ["user", user.user_address],
    mutationFn: async (data: UpdateProfileParams) => {
      const res = await stonkClient.post(
        `user/${data.user_address}/profile/`,
        null,
        { params: data }
      );
      return res.data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user", user.user_address] });
    },
  });

  const resetState = () => {
    setFormState({
      username: user.username,
      usernameError: "",
      bio: user.bio,
      bioError: "",
      image: user.image,
    });
    setSelectedFile(null);
  };
  useEffect(() => {
    resetState();
  }, [user, isOpen]);

  const handleSubmit = async () => {
    if (!wallet) return;
    let image: string | undefined = formState.image;
    if (selectedFile) {
      image = await uploadImageFromForm(selectedFile);
    }
    updateProfile.mutate({
      user_name: formState.username,
      image,
      bio: formState.bio,
      user_address: wallet.address,
      user_signature: wallet.getSignature(),
    });
    setIsOpen(false);
  };

  return (
    <Dialog onOpenChange={(open) => setIsOpen(open)} open={isOpen}>
      <DialogTrigger>
        <div className="hover:cursor-pointer">
          <Edit />
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Profile</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-5">
          <div className="flex items-center gap-3">
            <p>Profile photo</p>
            <div className="border-gra-400 relative rounded-full border">
              <Image
                variant="profile"
                src={selectedImageUrl || user.image}
                alt="Profile image"
                isNsfw={false}
                address={user.user_address}
                className="h-10 w-10 rounded-full"
              />
              <Input
                id="profile_image"
                name="profile_image"
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    setSelectedFile(file);
                  }
                }}
              />
              <FaRegEdit
                className="absolute -bottom-1 -right-2 cursor-pointer overflow-visible"
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              />
              {/* <Cross2Icon className="absolute -top-1 -right-1 overflow-visible cursor-pointer" /> */}
            </div>
          </div>
          <div className="flex gap-3">
            <p className="mt-1">Username</p>
            <div className="w-full">
              <Input
                type="text"
                className="w-full"
                value={formState.username}
                onChange={(e) =>
                  setFormState((f) => ({ ...f, username: e.target.value }))
                }
                onBlur={() => {
                  let err = "";
                  if (formState.username.length < 3) {
                    err = "Too short";
                  } else if (formState.username.length > 20) {
                    err = "Too long";
                  } else if (!/^[a-zA-Z0-9_]*$/.test(formState.username)) {
                    err =
                      "Invalid characters. Only letters, numbers and _ allowed.";
                  }
                  setFormState((f) => ({ ...f, usernameError: err }));
                }}
              />
              <p className="text-sm text-red">{formState.usernameError}</p>
            </div>
          </div>
          <div className="flex gap-3">
            <p className="mt-1">Bio</p>
            <div className="w-full">
              <textarea
                className="font-inter w-full rounded-lg bg-[#1A1A1A] p-2 text-[15px] font-normal text-[#BFDBFE] outline-none"
                rows={4}
                placeholder="Write your bio..."
                value={formState.bio}
                onChange={(e) =>
                  setFormState((f) => ({ ...f, bio: e.target.value }))
                }
                onBlur={() => {
                  let err = "";
                  if (formState.bio.length > 160) {
                    err = "Too long";
                  }
                  setFormState((f) => ({ ...f, bioError: err }));
                }}
              />
              <p className="text-sm text-red">{formState.bioError}</p>
            </div>
          </div>
        </div>
        <DialogFooter>
          <DialogClose>Close</DialogClose>
          <Button
            type="submit"
            size="md"
            onClick={handleSubmit}
            disabled={!!formState.usernameError || !!formState.bioError}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditProfile;
